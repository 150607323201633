<template>
    <sys-register-base
      :loading="loading"
      @onSave="save"
      :model.sync="item"
      :title="model && item.id ? `Atualizar categoria` : `Cadastrar categoria`"
      icon="mdi-buffer"
      v-bind:show.sync="dialog"
      maxWidth="30%"
    >
        <template v-slot:form>
            <v-row class="mt-1">
                <v-col cols="12" v-if="message">
                    <v-alert
                        outlined
                        color="warning"
                        prominent
                        border="left"
                        >
                        {{ message }}
                        </v-alert>
                </v-col>
                <EmcFormTextField 
                    v-model="item.name" 
                    label="Nome*" 
                    md="12"
                    outlined
                    :error="errors.length > 0"
                    :error-messages="errors.name"
                />
                <EmcFormTextField 
                    v-model="item.icon" 
                    label="Icone" 
                    md="6"
                    outlined
                    :error="errors.length > 0"
                    :error-messages="errors.icon"
                />
                <EmcFormTextField 
                    v-model="item.color" 
                    label="Cor" 
                    md="6"
                    outlined
                    :error="errors.length > 0"
                    :error-messages="errors.color"
                />
            </v-row>
        </template>
    </sys-register-base>
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: "TicketFormRegisterCategory",
    data () {
        return {
            loading: false,
            message: null,
            errors: {}
        }
    },
    props: {
        show: { type: Boolean, default: false },
        model: { default: null },
        title: { default: "título" }
    },
    computed: {
        dialog: {
            get() {
                return this.show
            },
            set(value) {
                this.$emit('update:show', value)
            }
        },
        item: {
            get() {
                return this.model
            },
            set(value) {
                this.$emit('model', value)
            }
        }
    },
    methods: {
        ...mapActions("ticket", ["ActionTicketCategoriesStore", "ActionTicketCategoriesUpdate"]),
        initVariables() {
            this.loading = !this.loading
            this.message = null
            this.errors = {}
        },
        save(edit) {
            
            this.initVariables()

            if(edit) {
                this.ActionTicketCategoriesUpdate(this.model)
                    .then((res) => {
                        this.message = null;
                        this.$emit('onCompleted', res.data);
                    })
                    .catch((error) => {
                        this.errors     = error.errors;
                        this.message    = error.message;
                    })
                    .finally(() => {
                        this.loading = !this.loading
                    })
            } else {
                this.ActionTicketCategoriesStore(this.model)
                    .then((res) => {
                        this.message = null;
                        this.$emit('onCompleted', res.data);
                    })
                    .catch((error) => {
                        this.errors     = error.errors;
                        this.message    = error.message;
                    })
                    .finally(() => {
                        this.loading = !this.loading
                    })
            }
        }
    }
}
</script>