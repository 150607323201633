<template>
    <main-container :icon="$route.meta.icon" :title="$route.meta.title">
        <emc-table-items
            :headers="headers"
            v-bind:params.sync="params"
            v-bind:collections.sync="collections"
            v-bind:meta.sync="meta"
            v-bind:itemDelete.sync="itemDelete"
            v-bind:itemEdit.sync="model"
            :loading="loading"
        >
            <template v-slot:item.icon="{item}">
                <v-icon :color="item.color" small>{{ item.icon }}</v-icon>
            </template>
        </emc-table-items>

        <template v-slot:tools>
            <EmcButtonIconCreate
                @update:show="(value) => showForm = value"
            />
        </template>

        <FormRegisterCategory
            :show="showForm"
            @update:show="(value) => showForm = value"
            :model="model"
            @onCompleted="getData()"
        />

        <emc-alert-modal :text="text" v-bind:show.sync="show">
            <template v-slot:actions>
                <v-btn color="error" @click.prevent.stop="deleteItem()" :loading="deleting" :disabled="deleting">Excluir <v-icon right dark>mdi-delete</v-icon></v-btn>
            </template>
        </emc-alert-modal>
    </main-container>
</template>

<script>

import { mapActions } from 'vuex'
import FormRegisterCategory from './FormRegisterCategory.vue'

export default {
    name: 'TicketCategoryIndex',
    data () {
        return {
            headers: [
                { text: 'Nome', value: 'name', sortable: false},
                { text: 'Icone', value: 'icon', sortable: false, align: "center"},
                { text: 'Ações', value: 'actions', sortable: false}
            ],
            params: {},
            collections: [],
            meta: {},
            itemDelete: {},
            model: {},
            loading: false,
            showForm: false,
            show: false,
            text: '',
            message: '',
            deleting: false,
            error: false
        }
    },
    components: {
        FormRegisterCategory
    },
    watch: {
        params(new_value, old_value) {
            if(new_value != old_value) {
                this.getData()
            }
        },
        model(new_value, old_value) {
            if(new_value !== old_value && (this.model && this.model.id))
            {
                this.showForm = true
                this.message = null

            } else if(Object.keys(this.model).length === 0){
                this.showForm = false;
            }
        },
        itemDelete(new_value, old_value) {
            if(new_value !== old_value && Object.keys(new_value).length > 0)
            {
                this.show = true
                this.text = "Deseja excluir " + new_value.name + "?"
            } else {
                this.show = false
                this.text = null
            }
        },
        showForm(val) {
            if(!val) {
                this.model = {}
            }
        }
    },
    methods: {
        ...mapActions('ticket', ["ActionTicketCategoriesIndex", "ActionTicketCategoriesDestroy"]),
        getData() {
            this.showForm = false;
            this.loading = !this.loading

            this.ActionTicketCategoriesIndex(this.params)
                .then((r) => {
                    this.collections = r.data
                    this.meta = r.meta
                })
                .finally(() => {
                    this.loading = false
                })
        },
        deleteItem() {
            this.deleting = true;
            this.message = null;

            this.ActionTicketCategoriesDestroy({ id: this.itemDelete.id })
                .then((res) => {
                    this.message = res.message;
                    this.show = false;
                    this.getData();
                })
                .catch((error) =>{
                    this.message = error.message;
                    this.error = true;
                })
                .finally(() => {
                    this.deleting = false;
                });
        }
    }
}
</script>